<template>
  <div>
    <b-modal ref="search-more-modal" id="search-more-modal" title="ค้นหา" size="lg" hide-footer no-close-on-backdrop>
      <b-card-text>
        <b class="text-primary">ค้นหาด้วยข้อมูลพื้นฐาน</b>
        <b-row class="">
          <b-col md="6" class="">
            <label>Serial</label>           
              <b-form-input placeholder="Serial" size="sm" v-model="serial" />
          </b-col>
        </b-row>
        <!--- END (Serial) --->

        <!--- START (วันที่) --->
        <b-row class="mt-1">
          <b-col md="6" class="">
            <label>วันที่เริ่มต้น</label>
            <b-form-datepicker
              id="example-datepicker"
              locale="th-TH"
              v-model="dateStart"
              show-decade-nav
              class="mb-1"
              size="sm"
            />
          </b-col>
          <b-col md="6" class="">
            <label>วันที่สิ้นสุด</label>
            <b-form-datepicker
              id="example-datepicker2"
              locale="th-TH"
              v-model="dateEnd"
              show-decade-nav
              class="mb-1"
              size="sm"
            />
          </b-col>
        </b-row>
        <!--- END (วันที่) --->

        <!--- START (ประเภทงาน) --->
        <b-row class="mb-2">
          <b-col cols="6">
            <label>พนักงาน (ช่าง)</label>
            <v-select
              id="brand"
              v-model="employeeSelected"
              label="fullname"
              :options="employeeOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>
          <b-col cols="6">
            <label>สถานะ</label>
            <v-select
              id="brand"
              v-model="movementTypeSelected"
              label="title"
              :options="movementTypeOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>

        </b-row>
        <!--- END (ประเภทงาน) --->

        <b class="text-primary">ค้นหาด้วยอุปกรณ์</b>
        <!--- START (ประเภทอุปกรณ์) --->
        <b-row class="mb-2">
          <b-col md="4" class="">
            <label>ประเภทอุปกรณ์</label>
            <v-select
              id="brand"
              v-model="hwTypeSelected"
              label="hwTypeName"
              :options="hwTypeOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeHwType"
            />
          </b-col>
          <b-col md="4" v-if="hwTypeSelected.id!==0">
            <label>ยี่ห้อ</label>
            <v-select
              id="brand"
              v-model="hwBrandSelected"
              label="hwBrandName"
              :options="hwBrandOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeHwBrand"
            />
          </b-col>
          <b-col md="4" v-if="hwTypeSelected.id!==0">
            <label>รุ่น</label>
            <v-select
              id="brand"
              v-model="hwModelSelected"
              label="hwModelName"
              :options="hwModelOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>
        </b-row>
        <!--- END (ประเภทอุปกรณ์) --->        

       
        
        <b class="text-primary">ค้นหาด้วยลูกค้า</b>
        <!--- START (ลูกค้า) --->
        <b-row>
          <b-col cols="6">
            <label>ลูกค้า</label>
            <v-select
              id="brand"
              v-model="ownerSelected"
              label="ownerName"
              :options="ownerOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeCustomerOwner"
            />
          </b-col>
          <b-col cols="6" v-if="ownerSelected.id!==0">
            <label>Brand</label>
            <v-select
              id="brand"
              v-model="companySelected"
              label="companyName"
              :options="companyOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeCustomerCompany"
            />
          </b-col>          
        </b-row>
        <!--- END (ลูกค้า) --->

        <!--- START (ลูกค้า) --->
        <b-row v-if="ownerSelected.id!==0">
          <b-col cols="6">
            <label>บริษัท</label>
            <v-select
              id="brand"
              v-model="subCompanySelected"
              label="subCompanyName"
              :options="subCompanyOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeCustomerSubCompany"
            />
          </b-col>
          <b-col cols="6">
            <label>สาขา</label>
            <v-select
              id="brand"
              v-model="branchSelected"
              label="branchName"
              :options="branchOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>          
        </b-row>
        <!--- END (ลูกค้า) --->    






        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-2">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button variant="primary" size="sm" @click="onSearch">บันทึก</b-button>
          </b-col>
        </b-row>
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BButton
} from "bootstrap-vue";
import vSelect from "vue-select";

import UserService from "@/services/user";
import CustomerService from "@/services/setting/customer";
import HardwareService from "@/services/setting/hardware";

export default {
  props:['getRepairHistoryStock'],
  components: {
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
    BButton
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      serial:"",
      employeeSelected: { fullname:"เลือกทั้งหมด", userId:0 },
      movementTypeSelected: { title: "เลือกทั้งหมด", value: "All" },    
      employeeOption: [],
      movementTypeOption: [
        { title: "เลือกทั้งหมด", value: "All" },
        { title: "รอการตรวจสอบ", value: "Wait" },
        { title: "ซ่อมเรียบร้อย", value: "Complete" },
        { title: "เสีย", value: "Bad" },
      ],      
      hwTypeSelected: {hwTypeName:"เลือกทั้งหมด", id:0},
      hwBrandSelected: {},
      hwModelSelected: {},
      hwTypeOption: [],
      hwBrandOption: [],
      hwModelOption: [],
      ownerSelected: {ownerName:"เลือกทั้งหมด", id:0},
      ownerOption: [],
      companySelected: {},
      companyOption: [],
      subCompanySelected: {},
      subCompanyOption: [],  
      branchOption: [],
      branchSelected: {},   
      dateStart: "",
      dateEnd: "",
    };
  },
  created(){
    this.getUsers()
    this.getHwType()
    this.getCustomerOwner()
  },
  methods:{
    onSearch(){
      let queryString = []
      if(this.serial){
        queryString.push(`serial=${this.serial}`)
      }

      if(this.dateStart&&this.dateEnd){
        queryString.push(`dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`)
      }

      if(this.movementTypeSelected.value !=="All"){
        queryString.push(`status=${this.movementTypeSelected.value}`)
      }

      if(this.employeeSelected.userId!==0){
        queryString.push(`assignTo=${this.employeeSelected.userId}`)
      }

      if(this.branchSelected.id && this.ownerSelected.id!==0){
        queryString.push(`branchId=${this.branchSelected.id}`)
      }

      if(this.hwTypeSelected.id && this.hwTypeSelected.id!==0){
        queryString.push(`hwTypeId=${this.hwTypeSelected.id}`)
      }

      if(this.hwBrandSelected.id && this.hwTypeSelected.id!==0){
        queryString.push(`hwBrandId=${this.hwBrandSelected.id}`)
      }

      if(this.hwModelSelected.id && this.hwTypeSelected.id!==0){
        queryString.push(`hwModelId=${this.hwModelSelected.id}`)
      }

      const joined = queryString.join("&")
      this.getRepairHistoryStock(joined)
      this.hideModal()
    }, 
    async getUsers(){      
      const result = await UserService.GetUserList({})
      this.employeeOption.push({fullname:"เลือกทั้งหมด", userId:0})
      this.employeeOption = [...this.employeeOption, ...result.data.data]  
      this.employeeSelected = {fullname:"เลือกทั้งหมด", userId:0}
    },
    async getCustomerOwner() {
      const result = await CustomerService.GetCustomerOwnerList({});
      this.ownerOption.push({ownerName:"เลือกทั้งหมด", id:0})
      this.ownerOption = [...this.ownerOption, ...result.data.data];
      if (result.data.data.length > 0) {
        let item = result.data.data[0];  
        this.onChangeCustomerOwner(item);
      }else{
        this.ownerSelected = {}
      }
    },

    async getCustomerCompany(ownerObj) {
      const result = await CustomerService.GetCustomerCompanyList(ownerObj.id);
      this.companyOption = result.data.data;
      if (result.data.data.length > 0) {
        let item = result.data.data[0];        
        this.companySelected = item;
        this.onChangeCustomerCompany(item);
      }else{
        this.companySelected = {}
      }
    },

    async getCustomerSubCompany(companyObj) {
      const result = await CustomerService.GetCustomerSubCompanyList(
        companyObj.id
      );
      this.subCompanyOption = result.data.data;
      if (result.data.data.length > 0) {        
        this.subCompanySelected = result.data.data[0]; 
        this.onChangeCustomerSubCompany(result.data.data[0]);
      }else{
        this.subCompanySelected = {}
      }
    },

    async getCustomerBranch(subCompanyObj) {
      const result = await CustomerService.GetCustomerBranchList(
        subCompanyObj.id
      );
      this.branchOption = result.data.data;
      if (result.data.data.length > 0) {      
        this.branchSelected = result.data.data[0];        
      }else{
        this.branchSelected = {}
      }
    },    
    onChangeCustomerOwner(item) {
      this.getCustomerCompany(item);
    },
    onChangeCustomerCompany(item) {
      this.getCustomerSubCompany(item);
    },
    onChangeCustomerSubCompany(item) {
      this.getCustomerBranch(item);
    },

    async getHwType() {
      const result = await HardwareService.GetHwTypeList();
      this.hwTypeOption.push({hwTypeName:"เลือกทั้งหมด", id:0}) 
      this.hwTypeOption = [...this.hwTypeOption,...result.data];

      if (result.data.length > 0) {
        let item = result.data[0];
        await this.onChangeHwType(item);
      }else{
        this.hwTypeSelected = {}
      }
    },

    async getHwBrand(typeId) {  
      const result = await HardwareService.GetHwBrandList(typeId);
      this.hwBrandOption = result.data;      

      if (result.data.length > 0) {
        let item = result.data[0];    
        this.hwBrandSelected = item;
        await this.onChangeHwBrand(item);
      }else{
        this.hwBrandSelected = {}
      }
    },

    async getHwModel(brandId) {
      const result = await HardwareService.GetHwModelList(brandId);
      this.hwModelOption = result.data;
      if (result.data.length > 0) {      
          this.hwModelSelected = result.data[0];        
      }else{
        this.hwModelSelected = {}
      }
    },
    async onChangeHwType(item) {
      await this.getHwBrand(item.id);
    },

    async onChangeHwBrand(item) {
      await this.getHwModel(item.id);
    },
    hideModal() {
      this.$refs['search-more-modal'].hide()
    },
  }
};
</script>

<style>
@import "./style.css";
</style>
