import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const HardwareService = () => {}

//TYPE
HardwareService.GetHwTypeList = async(mode="stock",queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/hardwareType/mode/${mode}?${queryString}`, {})
 }

 HardwareService.GetHwTypeById = async(hwTypeId)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/hardwareType/${hwTypeId}`, {})
 } 

 HardwareService.InsertHwType = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/hardwareType`, payload)
 }  

 HardwareService.UpdateHwType = async(payload, hwTypeId)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/hardwareType/${hwTypeId}`, payload)
 }

 HardwareService.DeleteHwType = async(hwTypeId)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/hardwareType/${hwTypeId}`, {})
 }


 //BRAND
HardwareService.GetHwBrandList = async(typeId, stockType="stock", queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/hardwareBrand/hwtype/${stockType}/${typeId}?${queryString}`, {})
 }


 HardwareService.InsertHwBrand = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/hardwareBrand`, payload)
 } 

 HardwareService.UpdateHwBrand = async(payload, hwBrandId)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/hardwareBrand/${hwBrandId}`, payload)
 }

 HardwareService.DeleteHwBrand = async(hwBrandId)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/hardwareBrand/${hwBrandId}`, {})
 }


 //MODEL
 HardwareService.GetHwModelList = async(brandId, stockType="stock", queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/hardwareModel/hwbrand/${stockType}/${brandId}?${queryString}`, {})
 } 

 HardwareService.InsertHwModel = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/hardwareModel`, payload)
 } 

 HardwareService.UpdateHwModel = async(payload, hwModelId)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/hardwareModel/${hwModelId}`, payload)
 }

 HardwareService.DeleteHwModel = async(hwModelId)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/hardwareModel/${hwModelId}`, {})
 } 
 
 
 export default HardwareService