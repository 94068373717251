<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>Stock Repair History</b></h2>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 30%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>
              <b-button variant="outline-primary" class="ml-1 shadow" v-b-modal.search-more-modal
                >Search more <feather-icon icon="FilterIcon" size="14"
              /></b-button>
            </b-form>
          </b-col>

          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
            >
            
            <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>    

              <template #cell(repairDate)="row">
                <center>{{ convertDate(row.item.repairDate, "date") }}</center>
              </template>   

              <template #cell(createAt)="row">
                <center>{{ convertDate(row.item.createAt) }}</center>
              </template>  
              
              <template #cell(historyType)="row">
                <center><b-badge :variant="chechActionColor(row.item.historyType)">{{ row.item.historyType }}</b-badge></center>
              </template> 
              
            </b-table>
          </b-col>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <SearchMoreModal :getRepairHistoryStock="getRepairHistoryStock"/>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BForm,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";


import RepairStockHistoryService from "@/services/stock/repairHistory";
import SearchMoreModal from "./component/modal/searchMoreModal.vue"
import Field from "./field";
import moment from "moment";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    BSpinner,
    vSelect,
    SearchMoreModal,
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],     
      isLoading:false,
   
    };
  },

  computed :{
    stockPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("STK006")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission       
    },

  },
 
  mounted() {
    this.getRepairHistoryStock();
    
  },
  methods: {
    async getRepairHistoryStock(queryString="") {
      this.isLoading = true
      const result = await RepairStockHistoryService.GetRepairStockHistory(queryString);
      this.items = result.data.data;
      this.totalRows = this.items.length;
      this.isLoading = false
    },   

    convertDate(date, type="") {
      if(type=="date"){
        return moment(date).format("yyyy-MM-DD");
      }
      return moment(date).subtract(7,'h').format("yyyy-MM-DD HH:mm:ss");
    },

    chechActionColor(type){
      if(type=="add"){
        return "success"
      }else if(type=="update"){
        return "info"
      }else if(type=="delete"){
        return "danger"
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
