import { BaseUrl } from './ApiUrl'
import AXIOS_SERVICE from '../utils/AxiosUtil'

const UserService = () => {}

UserService.GetUserList = async(payload, queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/user?${queryString}`, payload)
 }

 UserService.GetUserById = async(userId)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/user/${userId}`, {})
 }

 UserService.InsertUser = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/user`, payload)
 } 

 UserService.UpdateUser = async(payload,id)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/user/${id}`, payload)
 } 

 UserService.ChangeUserPassword = async(password,id)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/user/change-password/${id}`, {password})
 } 

 UserService.DeleteUser = async(id)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/user/${id}`, {})
 }  
 
 export default UserService