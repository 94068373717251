import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const RepairStockHistoryService = () => {}

RepairStockHistoryService.GetRepairStockHistory = async(queryString)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/repairHistory?${queryString}`, {})
}

RepairStockHistoryService.InsertRepairStockHistory = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/stock/repairHistory`, payload)
}

 export default RepairStockHistoryService